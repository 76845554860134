
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const GATEWAY_URL = 'https://api-gateway-dev.trantec-dev.com';

export const environment = {
  production: false,
  api: {
    /*auth*/
    login: GATEWAY_URL + '/authentication-api/v0/login',
    otp: GATEWAY_URL + '/authentication-api/v0/users/{userId}/otp',
    user: GATEWAY_URL + '/authentication-api/v0/users',
    userId: GATEWAY_URL + '/authentication-api/v0/users/{userId}',
    userStyle: GATEWAY_URL + '/authentication-api/v0/users/{userId}/styles',
    recoveryPassword: GATEWAY_URL + '/authentication-api/v0/users/{userId}/recovery-password',
    accountingInformation: GATEWAY_URL + '/authentication-api/v0/providers/{providerId}',
    providers: GATEWAY_URL + '/authentication-api/v0/providers',
    updateUser: GATEWAY_URL + '/authentication-api/v0/users/{userId}',
    temporalPassword: GATEWAY_URL + '/authentication-api/v0/users/{userId}/temporal-password',
    distributors: GATEWAY_URL + '/authentication-api/v0/distributors',
    commerces: GATEWAY_URL + '/authentication-api/v0/commerces',
    commercesId: GATEWAY_URL + '/authentication-api/v0/commerces/{commerceId}',
    client: GATEWAY_URL + '/authentication-api/v0/client/{document}',
    addClient: GATEWAY_URL + '/authentication-api/v0/client',
    roles: GATEWAY_URL + '/authentication-api/v0/user/roles',
    companies: GATEWAY_URL + '/authentication-api/v0/companies',
    dealer: GATEWAY_URL + '/authentication-api/v0/distributors',
    companyId: GATEWAY_URL + '/authentication-api/v0/companies/{companyId}',
    informationBusinessCommerce: GATEWAY_URL + '/authentication-api/v0/information/business/commerce/{commerceId}',
    distribuitorId: GATEWAY_URL + '/authentication-api/v0/distributors/{distributorId}',
    balance: GATEWAY_URL + '/authentication-api/v0/get-balance/{userId}',
    pointOfSale: GATEWAY_URL + '/authentication-api/v0/pos',
    auth: GATEWAY_URL + '/authentication-api/v0/',
    acceptanceDeclarations: GATEWAY_URL + '/authentication-api/v0/acceptanceDeclarations',
    sidebarItemsCommerce: GATEWAY_URL + '/authentication-api/v0/access/sidebar',
    typePlatform: GATEWAY_URL + '/authentication-api/v0/type-platforms',
    menus: GATEWAY_URL + '/authentication-api/v0/menus',
    modules: GATEWAY_URL + '/authentication-api/v0/modules',
    profiles: GATEWAY_URL + '/authentication-api/v0/profiles',
    access: GATEWAY_URL + '/authentication-api/v0/access',
    accessUser:  GATEWAY_URL + '/authentication-api/v0/access/user',
    profileAccess: GATEWAY_URL + '/authentication-api/v0/access/profiles/{profileId}',


    /*admin*/
    advertising: GATEWAY_URL + '/admin-api/v0/advertisements',
    advertisingId: GATEWAY_URL + '/admin-api/v0/advertisements/{id}',
    countries: GATEWAY_URL + '/admin-api/v0/countries',
    departmentsCountries: GATEWAY_URL + '/admin-api/v0/departments-countries',
    citiesDepartments: GATEWAY_URL + '/admin-api/v0/cities-departments',
    typeDocument: GATEWAY_URL + '/admin-api/v0/type-document',
    banks: GATEWAY_URL + '/admin-api/v0/banks',
    typeAccounts: GATEWAY_URL + '/admin-api/v0/type-accounts',
    contacts: GATEWAY_URL + '/admin-api/v0/contacts',
    areaDirectories: GATEWAY_URL + '/admin-api/v0/area-directories',
    businessActivityCiiu: GATEWAY_URL + '/admin-api/v0/business-activity-ciiu',
    typeProviders: GATEWAY_URL + '/admin-api/v0/type-providers',
    socialNetworks: GATEWAY_URL + '/admin-api/v0/social-networks',
    informationNetworks: GATEWAY_URL + '/admin-api/v0/information-networks',
    fiscalResponsibility: GATEWAY_URL + '/admin-api/v0/fiscal-responsibility',
    documents: GATEWAY_URL + '/admin-api/v0/documents',
    informationNetworksComerces: GATEWAY_URL + '/admin-api/v0/information-networks-commerces',
    categoryBusiness: GATEWAY_URL + '/admin-api/v0/category-business',
    typeEntity: GATEWAY_URL + '/admin-api/v0/type-entities',
    typeIdentificationId: GATEWAY_URL + '/admin-api/v0/type-identifications',
    paymentMethod: GATEWAY_URL + '/admin-api/v0/payment-methods',
    courtFrequencies: GATEWAY_URL + '/admin-api/v0/type-courts',
    court: GATEWAY_URL + '/admin-api/v0/courts-configuration/entities/{entityId}',
    typeHousing: GATEWAY_URL + '/admin-api/v0/type-housing',
    infotmationNetworksDistributors: GATEWAY_URL + '/admin-api/v0/information-networks-distributors',
    subcategoryProduct: GATEWAY_URL + '/admin-api/v0/subcategory-products',
    admin: GATEWAY_URL + '/admin-api/v0/',
    /*integrations*/
    urlPublicUpload: GATEWAY_URL + '/integrations-api/v0/public/uploads',
    urlPublicUploadLogo: GATEWAY_URL + '/integrations-api/v0/public/uploadsLogo',
    upload: GATEWAY_URL + '/integrations-api/v0/uploads',
    metabaseToken: GATEWAY_URL + '/integrations-api/v0/analytic/generate-token',
    integrations: GATEWAY_URL + '/integrations-api/v0/',
    /*puntored*/
    suppliers: GATEWAY_URL + '/puntored-api/v0/recharges/suppliers',
    packages: GATEWAY_URL + '/puntored-api/v0/find-packages',
    packagesSuppliers: GATEWAY_URL + '/puntored-api/v0/find-suppliers',
    packagesCategories: GATEWAY_URL + '/puntored-api/v0/find-categories',
    /*product*/
    product: GATEWAY_URL + '/product-api/v0/products',
    productsByProvider: GATEWAY_URL + '/product-api/v0/products-provider',
    productsStates: GATEWAY_URL + '/product-api/v0/products/states',
    productsTypes: GATEWAY_URL + '/product-api/v0/products/types',
    statusGlobalId: GATEWAY_URL + '/product-api/v0/products/states',
    suppliersPortfolioStates: GATEWAY_URL + '/product-api/v0/supplier-portfolio/states',
    productsCategories: GATEWAY_URL + '/product-api/v0/product-category/type',
    suppliersPortfolio: GATEWAY_URL + '/product-api/v0/supplier-portfolio',
    userProfiles: GATEWAY_URL + '/admin-api/v0/user-profiles',
    userProfilesByUser: GATEWAY_URL + '/admin-api/v0/user-profiles/users/{userId}',
    productCategoryType: GATEWAY_URL + '/product-api/v0/product-category/type',
    commissionCategory: GATEWAY_URL + '/product-api/v0/commission-category',
    commissionType: GATEWAY_URL + '/product-api/v0/commission-type',
    typeTaxes: GATEWAY_URL + '/product-api/v0/type-taxes',

    /*transactional*/
    buyPackage: GATEWAY_URL + '/transactional-api/v0/packages/buy',
    recharges: GATEWAY_URL + '/transactional-api/v0/recharges',
    cache: GATEWAY_URL + '/v0/cache',
    sendOtp: GATEWAY_URL + '/authentication-api/v0/users/{userId}/send-otp',
    sendSms: GATEWAY_URL + '/integrations-api/v0/sms/messages',
    sendWsp: GATEWAY_URL + '/integrations-api/v0/messages',
    sendWhatsapp: GATEWAY_URL + '/integrations-api/v0/messages',
    order: GATEWAY_URL + '/transactional-api/v0/order',
    orderGet: GATEWAY_URL + '/transactional-api/v0/order/{orderId}',
    getOrdersByUserId: GATEWAY_URL + '/transactional-api/v0/orders/{userId}',
    saveretrieveterms: GATEWAY_URL + '/transactional-api/v0/terms',
    reportOrder: GATEWAY_URL + '/transactional-api/v0/order/report/{userId}',
    balanceBnpl: GATEWAY_URL + '/transactional-api/v0/transactions-summary/{userId}',
    /*bnpl*/

    urlIp1: 'https://ipv4.myexternalip.com/json',
    urlIp: 'https://geolocation-db.com/json/',
    provideAuthenticationTrx: GATEWAY_URL + '/transactional-api/v0/bnplFua',
    sessionTrx: GATEWAY_URL + '/transactional-api/v0/authSession',
    retrievequotaTrx: GATEWAY_URL + '/transactional-api/v0/retrieveQuota',
    selectaccountTrx: GATEWAY_URL + '/transactional-api/v0/selectAccount',
    retrievetermsTrx: GATEWAY_URL + '/transactional-api/v0/retrieveTerms',
    purchaseorderTrx: GATEWAY_URL + '/transactional-api/v0/purchaseOrder',
    originationorderTrx: GATEWAY_URL + '/transactional-api/v0/originationOrder',
    prospectTrx: GATEWAY_URL + '/transactional-api/v0/bnpl/validateProspect',
    queryBalanceTrx: GATEWAY_URL + '/transactional-api/v0/bnpl/balanceBnpl',
    

  },
  web: {
    clientId: '328115959256-gjds3ha3rj4u7o8rd1uiqgs1tvl4th4c.apps.googleusercontent.com',
    projectId: 'yp-online-378316',
    authUri: 'https://accounts.google.com/o/oauth2/auth',
    tokenUri: 'https://oauth2.googleapis.com/token',
    orderUri: 'https://bnpl-yp-dev.trantec-dev.com/order?id=',
    pdfTyCUri: 'https://dev-tr-bnpl-tyc.s3.amazonaws.com/',
    clientSecret: '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'
  },
  firebaseConfig: {
    apiKey: 'AIzaSyDVR_te1EZCMk2H6chKKRtg284pEYwDDrQ',
    authDomain: 'yp-online-378316.firebaseapp.com',
    projectId: 'yp-online-378316',
    storageBucket: 'yp-online-378316.appspot.com',
    messagingSenderId: '328115959256',
    appId: '1:328115959256:web:7683500fc55211babceead'
  },
  logo: '/assets/yopresto.png'
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
