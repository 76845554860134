import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import { FileTransfer } from '@awesome-cordova-plugins/file-transfer/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { FilePath } from '@awesome-cordova-plugins/file-path/ngx';
import { TermsPageModule } from './terms/terms.module';
import { MenuPageModule } from './menu/menu.module';
import { ChooseplanPageModule } from './chooseplan/chooseplan.module';
import { CinfoPageModule } from './cinfo/cinfo.module';
import { IncomeaddPageModule } from './incomeadd/incomeadd.module';
import { TaskaddPageModule } from './taskadd/taskadd.module';
import { TaskviewPageModule } from './taskview/taskview.module';
import { ProductAddPageModule } from './product-old/product-add/product-add.module';
import { ClientAddPageModule } from './client-add/client-add.module';
import { BnplPageModule } from './order/bnplauth/bnplauth.module';
import { PopoverContentComponent } from './popover-content/popover-content.component';
import { PayPal } from '@ionic-native/paypal/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Stripe } from '@awesome-cordova-plugins/stripe/ngx';
import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpInterceptorService } from './service/http-interceptor.service';
import { JwPaginationModule } from 'jw-angular-pagination';
import { CurrencyPipe } from '@angular/common';
import { registerLocaleData } from '@angular/common';
import localeES from '@angular/common/locales/es-CO';

registerLocaleData(localeES, 'es-CO');

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, FormsModule, ReactiveFormsModule, IonicModule.forRoot(), AppRoutingModule,
    HttpClientModule, TermsPageModule, MenuPageModule, ChooseplanPageModule, CinfoPageModule, IncomeaddPageModule,
    TaskaddPageModule, TaskviewPageModule, ProductAddPageModule, ClientAddPageModule, BnplPageModule, JwPaginationModule],
  providers: [CurrencyPipe, { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },

    StatusBar,
    SplashScreen,
    SocialSharing,
    Camera,
    File,
    FilePath,
    FileTransfer,
    PayPal,
    InAppBrowser,
    Stripe,
    OneSignal,
    GooglePlus,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    { provide: LOCALE_ID, useValue: 'es-CO' }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
