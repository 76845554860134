import { Component } from '@angular/core';
import { NavController,Platform,LoadingController,IonicSlides,ToastController,ModalController,NavParams } from '@ionic/angular';
import { ServerService } from '../service/server.service';
import { ActivatedRoute } from '@angular/router';
import {Constant} from "../shared/model/Constant";

@Component({
  selector: 'app-incomeadd',
  templateUrl: 'incomeadd.page.html',
  styleUrls: ['incomeadd.page.scss'],
})
export class IncomeaddPage {

text:any;
notes:any;
date_added:any;
amount:any;
data:any;
id:any;
date:any;
f:any;

constructor(public navParams: NavParams,public loadingController: LoadingController,public server : ServerService,private route: ActivatedRoute,public nav : NavController,public toastController: ToastController,public modalController: ModalController) {

this.text    = JSON.parse(localStorage.getItem(Constant.appText));
this.data    = navParams.get('data');

if(this.data && this.data.id)
{
  this.amount     = this.data.amount;
  this.date_added = this.data.date_added;
  this.notes      = this.data.notes;
  this.id         = this.data.id;
}

}

  ngOnInit()
  {

  }

  async saveData()
  {
    if(!this.amount)
    {
      return this.presentToast(this.text.enter_amount);
    }

    await this.modalController.dismiss({notes:this.notes,amount:this.amount,date_added:this.date_added,id:this.id});
  }

  async close()
  {
    await this.modalController.dismiss({});
  }

  async presentToast(txt) {
    const toast = await this.toastController.create({
      message: txt,
      duration: 3000,
      position : 'top',
      mode:'ios',
      color:'dark'
    });
    toast.present();
  }
}
