import { Component } from '@angular/core';
import { NavController,Platform,LoadingController,IonicSlides,ToastController,ModalController } from '@ionic/angular';
import { ServerService } from '../service/server.service';
import { ActivatedRoute } from '@angular/router';
import { NavParams } from '@ionic/angular';
import {Constant} from "../shared/model/Constant";

@Component({
  selector: 'app-cinfo',
  templateUrl: 'cinfo.page.html',
  styleUrls: ['cinfo.page.scss'],
})
export class CinfoPage {

data:any;
text:any;

constructor(public navParams: NavParams,public loadingController: LoadingController,public server : ServerService,private route: ActivatedRoute,public nav : NavController,public toastController: ToastController,public modalController: ModalController) {

this.data    = navParams.get('data');
this.text    = JSON.parse(localStorage.getItem(Constant.appText));

}

  ngOnInit()
  {

  }

  async saveData()
  {
    await this.modalController.dismiss({});
  }

}
