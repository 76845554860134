import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {map} from "rxjs/operators";
import { response } from 'express';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  urlProfile = environment.api.profiles;
  urlProfileId = environment.api.profileId;
  urlDealer = environment.api.dealer;
  urlAdvertising = environment.api.advertising;
  urlModule = environment.api.modules;
  urlModuleId = environment.api.moduleId;
  urlPublicUpload = environment.api.urlPublicUpload;
  urlPublicUploadLogo = environment.api.urlPublicUploadLogo;
  urlAdvertisingId = environment.api.advertisingId;
  urlProductsTypes = environment.api.productsTypes;
  urlSupplierPortfolioStates = environment.api.suppliersPortfolioStates;
  urlSupplierPortfolio = environment.api.suppliersPortfolio;
  userProfiles = environment.api.userProfiles;
  userProfilesByUser = environment.api.userProfilesByUser;
  urlCountries = environment.api.countries;
  urlDepartmentsCountries = environment.api.departmentsCountries;
  urlCitiesDepartments = environment.api.citiesDepartments;
  urlTypeDocument = environment.api.typeDocument;
  urlBanks = environment.api.banks;
  urlTypeAccounts = environment.api.typeAccounts;
  urlContacts = environment.api.contacts;
  urlAreaDirectories = environment.api.areaDirectories;
  urlBusinessActivityCiiu = environment.api.businessActivityCiiu;
  urlTypeProviders = environment.api.typeProviders;
  urlSocialNetworks = environment.api.socialNetworks;
  urlInformationNetworks = environment.api.informationNetworks;
  urlCache = environment.api.cache;
  urlFiscalResponsibility = environment.api.fiscalResponsibility;
  urlDocuments = environment.api.documents;
  urlInformationNetworksComerces = environment.api.informationNetworksComerces;
  urlCategoryBusiness = environment.api.categoryBusiness;
  urlCompanies = environment.api.companies;
  urlUserType = environment.api.typeEntity;
  urlTypeIdentificationId = environment.api.typeIdentificationId;
  urlPaymentMethod = environment.api.paymentMethod;
  urlCourtFrequencies = environment.api.courtFrequencies;
  urlTypeHousing = environment.api.typeHousing;
  urlInformationNetworksDistributor = environment.api.infotmationNetworksDistributors;
  urlCreateCourt = environment.api.court;
  urlAdmin = environment.api.admin;
  
  constructor(private http: HttpClient) {

  }

  getAllDealers() {
    return this.http.get(this.urlDealer)
      .pipe(map(results => results));
  }

  getAdvertisements(enabled: boolean) {
    return this.http.get(this.urlAdvertising, {params: {enabled}}).pipe(map(results => results));
  }

  createAdvertisements(body: any) {
    return this.http.post(this.urlAdvertising, body).pipe(map(results => results));
  }

  getModule() {
    return this.http.get(this.urlModule)
      .pipe(map(results => results));
  }


  createProfile(data) {
    return this.http.post(this.urlProfile, data)
      .pipe(map(results => results));
  }

  createModule(data) {
    return this.http.post(this.urlModule, data)
      .pipe(map(results => results));
  }

  getAllProfile() {
    return this.http.get(this.urlProfile)
      .pipe(map(results => results));
  }

  deleteModule(menuId) {
    const url = this.urlModuleId.replace("{moduleId}", menuId)
    return this.http.delete(url)
      .pipe(map(results => results));

  }

  deleteProfile(menuId) {
    const url = this.urlProfileId.replace("{profileId}", menuId)
    return this.http.delete(url)
      .pipe(map(results => results));

  }

  uploadFile(file: any, folder: string) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('folder', folder);
    return this.http.post(this.urlPublicUpload, formData,).pipe(map(results => results));
  }

  uploadFileLogo(file: any, folder: string, identity: string) {
    const formData = new FormData();
    if (Array.isArray(file.name)) {
      console.log('La propiedad name de la imagen es un array, por lo tanto de aqui tomo los datos de la imagen para el backend');
      formData.append('file', file.name[0]);
    } else {
      console.log('La propiedad name de la imagen NO es un array, Se toma el objeto completo la imagen para el backend');
      formData.append('file', file);
    }
    formData.append('folder', folder);
    formData.append('identity', identity);
    return this.http.post(this.urlPublicUploadLogo, formData,).pipe(map(results => results));
  }

  deleteAdvertisement(id) {
    const url = this.urlAdvertisingId.replace('{id}', id);
    return this.http.delete(url).pipe(map(results => results));

  }

  updateAdvertisementState(id: number, body: any) {
    const url = this.urlAdvertisingId.replace('{id}', String(id));
    return this.http.put(url, body).pipe(map(results => results));
  }


  getProductTypes() {
    return this.http.get(this.urlProductsTypes)
      .pipe(map(results => results));
  }

  getSupplierPortfolioStates() {
    return this.http.get(this.urlSupplierPortfolioStates)
      .pipe(map(results => results));

  }

  createSuppliersPortfolio(data) {
    return this.http.post(this.urlSupplierPortfolio, data)
      .pipe(map(results => results));
  }

  createUserProfile(data) {
    return this.http.post(this.userProfiles, data)
      .pipe(map(results => results));
  }

  getCountries() {
    return this.http.get(this.urlCountries)
      .pipe(map(results => results));
  }

  getDepartments(countryId?) {
    if (countryId) {
      let params = new HttpParams();
      params = params.append('countryId', countryId);
      return this.http.get(this.urlDepartmentsCountries, {params})
        .pipe(map(results => results));
    }
    return this.http.get(this.urlDepartmentsCountries)
      .pipe(map(results => results));
  }

  getCities(departmentId?) {
    if (departmentId) {
      let params = new HttpParams();
      params = params.append('departmentId', departmentId);
      return this.http.get(this.urlCitiesDepartments, {params})
        .pipe(map(results => results));
    }
    return this.http.get(this.urlCitiesDepartments)
      .pipe(map(results => results));
  }


  getTypeDocument() {
    return this.http.get(this.urlTypeDocument)
      .pipe(map(results => results));
  }

  getBanks() {
    return this.http.get(this.urlBanks)
      .pipe(map(results => results));
  }

  getTypeaccountbank() {
    return this.http.get(this.urlTypeAccounts)
      .pipe(map(results => results));
  }

  createContacts(contact, providerId, commerceId, distributorId) {
    let params = new HttpParams();
    if (providerId) {
      params = params.append('providerId', providerId);
    }
    if (commerceId) {
      params = params.append('commerceId', commerceId);
    }
    if (distributorId) {
      params = params.append('distributorId', distributorId);
    }
    return this.http.post(this.urlContacts, contact, {params})
      .pipe(map(results => results));
  }

  getContactAreaDirectories() {
    return this.http.get(this.urlAreaDirectories)
      .pipe(map(results => results));
  }

  getSupplierBusinessActivityCiiu() {
    return this.http.get(this.urlBusinessActivityCiiu)
      .pipe(map(results => results));
  }

  getSupplierTypeProviders() {
    return this.http.get(this.urlTypeProviders)
      .pipe(map(results => results));
  }

  getSocialNetworks() {
    return this.http.get(this.urlSocialNetworks)
      .pipe(map(results => results));
  }

  createInformationNeworks(data, providerId) {
    return this.http.post(this.urlInformationNetworks.concat("?providerId=", providerId), data)
      .pipe(map(results => results));

  }

  createShopsInformationNeworks(data, commerceId) {
    return this.http.post(this.urlInformationNetworksComerces.concat("?commerceId=", commerceId), data)
      .pipe(map(results => results));
  }


  deleteCache() {
    return this.http.delete(this.urlCache)
      .pipe(map(results => results));
  }

  getFiscalResponsibility() {
    return this.http.get(this.urlFiscalResponsibility)
      .pipe(map(results => results));
  }

  createDocuments(data, providerId, commerceId, distributorId) {
    let params = new HttpParams();

    if (providerId) {
      params = params.append('providerId', providerId);
    }
    if (commerceId) {
      params = params.append('commerceId', commerceId);
    }
    if (distributorId) {
      params = params.append('distributorId', distributorId);
    }

    return this.http.post(this.urlDocuments, data, {params})
      .pipe(map(results => results));

  }


  getCategoryBusiness() {
    return this.http.get(this.urlCategoryBusiness)
      .pipe(map(results => results));
  }

  getProfilesByUser(userId) {
    const url = this.userProfilesByUser.replace('{userId}', userId);
    return this.http.get(url)
      .pipe(map(results => results));
  }

  getAllCompanies() {
    return this.http.get(this.urlCompanies)
      .pipe(map(results => results));
  }

  getUserTypes() {
    return this.http.get(this.urlUserType)
      .pipe(map(results => results));
  }


  getTypeIdentificationId() {
    return this.http.get(this.urlTypeIdentificationId)
  }

  getPaymentMethods() {
    return this.http.get(this.urlPaymentMethod)
      .pipe(map(results => results));
  }

  getCourtFrequencies() {
    return this.http.get(this.urlCourtFrequencies)
      .pipe(map(results => results));
  }

  getTypeHousing() {
    return this.http.get(this.urlTypeHousing)
      .pipe(map(results => results));
  }

  getCompanyById(companyId: number) {
    const url = this.urlAdmin.concat("companies/", companyId.toString() );
    return this.http.get(url)
      .pipe(map(results => results));
  }

  getProvidersCompanies(companyId: number) {
    const url = this.urlAdmin.concat("providers-companies/", companyId.toString());
    return this.http.get(url)
      .pipe(map(results => results));
  }

  getCompanies() {
    const url = this.urlAdmin.concat("companies/");
    return this.http.get(url)
      .pipe(map(results => results));
  }

  findCompany(data) {
    return this.http.post(this.urlAdmin.concat("companies/search"), data)
    .pipe(map(results => results))
  }

  updateProvidersCompanies(data) {
    const url = this.urlAdmin.concat("providers-companies/update/");
    return this.http.put(url, data, { responseType: 'text'})
      .pipe(map(results => results));
  }

  getProviderById(providerId: number){
    const url = this.urlAdmin.concat("providers/", providerId.toString());
    return this.http.get(url)
    .pipe(map(results => results));
  }

  getProviders(){
    const url = this.urlAdmin.concat("providers/search/");
    return this.http.get(url)
    .pipe(map(results => results));
  }

  getAllEntities(userId: string){     //realiza la busqueda de todas las entidades existentes, solo para el superusuario
    const url = this.urlAdmin.concat("update-entities/findAll/",userId);
    return this.http.get(url)
    .pipe(map(results => results));
  }
  
  getEntityInfo(userId){          
    const url = this.urlAdmin.concat("update-entities/userInfoById/",userId); 
    return this.http.get(url)
    .pipe(map(results => results));
  }

  getEntitiesByEntityId(entityId: string){
    const url= this.urlAdmin.concat("update-entities/", entityId);
    return this.http.get(url)
    .pipe(map(results => results));
  }

  getEntitiesByUserId(userId: string){
    const url = this.urlAdmin.concat("update-entities/search/", userId);
    return this.http.get(url)
    .pipe(map(results => results));
  }

  updateEntities(data){
    const url = this.urlAdmin.concat("update-entities/updateInfo");
    return this.http.post(url, data, {responseType: 'text'})
    .pipe(map(results => results));
  }

  saveBankInformationProvider(providerId: string, data){
    const url = this.urlAdmin.concat("bank-information/provider/",providerId);
    return this.http.put(url,data, {responseType: 'text'})
      .pipe(map(results => results));
  }

  saveBankInformationCommerce(commerceId: string, data){
    const url = this.urlAdmin.concat("bank-information/commerce/",commerceId);
    return this.http.put(url,data, {responseType: 'text'})
      .pipe(map(results => results));
  }

  saveBankInformationDistributor(distributorId: string, data){
    const url = this.urlAdmin.concat("bank-information/distributor/",distributorId);
    return this.http.put(url,data, {responseType: 'text'})
      .pipe(map(response => response));
  }

  saveBankInformationCompany(companyId: string, data){
    const url = this.urlAdmin.concat("bank-information/company/",companyId);
    return this.http.put(url,data, {responseType: 'text'})
      .pipe(map(results => results));
  }

  findProvider(data) {
    return this.http.post(this.urlAdmin.concat("providers/search"), data)
    .pipe(map(results => results))
  }


  createInformationNeworksDistributor(data, distributorId) {
    return this.http.post(this.urlInformationNetworksDistributor.concat("?distributorId=", distributorId), data)
      .pipe(map(results => results));
  }

  createCourtConfig(data, entityId: string) { 
    return this.http.put(this.urlCreateCourt.replace('{entityId}', entityId), data)   //guarda la configuracion de corte
      .pipe(map(results => results));
  }

  getTypesCourt() {
    const url = this.urlAdmin.concat('type-courts'); //servicio para extraer los tipos de corte
    return this.http.get(url).pipe(map(results => results));
  }

  getTypesSettlement() {
    const url = this.urlAdmin.concat('types-settlement'); //servicio para extraer los tipos de liquidacion
    return this.http.get(url).pipe(map(results => results));
  }

  getCourts() {
    const url = this.urlAdmin.concat('courts-configuration'); //servicio para extraer los cortes junto con su configuracion
    return this.http.get(url).pipe(map(results => results));
  }

  getCourtById(courtId) {
    const url = this.urlAdmin.concat('courts-configuration/',courtId); //servicio para extraer un cortes junto con su configuracion por medio de su ID
    return this.http.get(url).pipe(map(results => results)); 
  }

  getTypePhotos() {
    const url = this.urlAdmin.concat('type-files'); //servicio para extraer los tipos de fotos para comercio 
    return this.http.get(url).pipe(map(results => results));
  }

  getAllPhotos() {          //extrae todos los valores de la tabla admin.archivos_entidades
    const url = this.urlAdmin.concat('files'); //servicio para extraer los tipos de fotos para comercio 
    return this.http.get(url).pipe(map(results => results));
  }

  getPhotosByEntity(entityId) {
    const url = this.urlAdmin.concat('files/entity-file/', entityId); //servicio para extraer los tipos de fotos para comercio 
    return this.http.get(url).pipe(map(results => results));
  }

  getPhotosByUrl(urlToSearch){
    const url = this.urlAdmin.concat('files/url-file');   //servicio para extraer una foto enviando su url de S3
    return this.http.post(url, urlToSearch).pipe(map(results => results));
  }

  savePhotoForEntity (data, entityId) {
    const url = this.urlAdmin.concat('files/save/', entityId); //servicio para guardar las fotos de una entidad
    return this.http.post(url,data).pipe(map(results => results));
  }

  updatePhotoForEntity(data) {
    const url = this.urlAdmin.concat('files/update'); //servicio para guardar las fotos de una entidad
    return this.http.put(url,data)
      .pipe(map(results => results));
  }

  getNetworkInformation(overviewInformationId) {
    const url = this.urlAdmin.concat('information-networks/overview-id/', overviewInformationId.toString())
    return this.http.get(url)
      .pipe(map(results => results));
  }

  updateSocialNetworks(data) {
    const url = this.urlAdmin.concat('information-networks');
    return this.http.put(url, data, {responseType: 'text'})
      .pipe(map(results => results));
  }


}
