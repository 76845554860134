import { Component, HostListener } from '@angular/core';

import { NavController, Platform, PopoverController } from '@ionic/angular';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';
import { AuthService } from './service/auth.service';
import { Constant } from './shared/model/Constant';
import { PopoverContentComponent } from './popover-content/popover-content.component';
import { ActivatedRoute, NavigationEnd, Route, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Role } from './shared/model/intefaces/UserRoles';
import { MenuItem } from './shared/model/intefaces/Menu.model';
import { ModulesGuard } from './shared/guards/modules.guard';
import { BreadcrumbHeaderService } from './service/breadcrumb-header.service';
import { register } from 'swiper/element/bundle';
import { AdminService } from './service/admin.service';

register(); // Registrar elementos de Swiper para el carrusel de advertising-page

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  setting: any;
  text: any;
  user: any;
  dark_mode = false;
  dir = 'ltr';
  lang_data: any;
  userId: string;
  isAuthorized: string;

  //Para mostrar el menu seleccionado
  isOpened: boolean = false;
  currentMenu: MenuItem = null;
  //Categoria con pagina principal que siempre estara presente en la barra latera
  mainModule: MenuItem = { label: 'Inicio', routerLink: '/main', title: 'Pagina de Inicio', icon: 'home' };
  menuItems: MenuItem[] = [];  //Variable para los modulos que se mostraran al usuario
  isMobile: boolean = window.innerWidth <= 800;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private nav: NavController,
    private router: Router,
    private oneSignal: OneSignal,
    private authService: AuthService,
    private adminService: AdminService,
    private popoverController: PopoverController,
    private breadcrumbService: BreadcrumbHeaderService
  ) {
    this.isAuthorized = localStorage.getItem(Constant.authorization);
    this.userId = localStorage.getItem(Constant.userId);
    this.getUser();
    if (localStorage.getItem(Constant.darkMode) == '1') {
      this.dark_mode = true;
    } else {
      this.dark_mode = false;
    }

    if (localStorage.getItem(Constant.darkMode) == '1') {
      document.body.setAttribute('color-theme', 'dark');
    } else {
      document.body.setAttribute('color-theme', 'light');
    }
    this.initializeApp();
    if (localStorage.getItem(Constant.setting) && localStorage.getItem(Constant.setting) != undefined) {
      this.setting = JSON.parse(localStorage.getItem(Constant.setting));
    }

    if (localStorage.getItem(Constant.appText) && localStorage.getItem(Constant.appText) != undefined) {
      this.text = JSON.parse(localStorage.getItem(Constant.appText));
    }

    // if (localStorage.getItem(Constant.userId) && localStorage.getItem(Constant.userId) != 'null') {
    //   //this.nav.navigateRoot('/main');.
    //   console.log('test')
    // } else {
    //   this.nav.navigateRoot('/login');
    // }

    if (localStorage.getItem(Constant.langData) && localStorage.getItem(Constant.langData) != "undefined") {
      this.lang_data = JSON.parse(localStorage.getItem(Constant.langData));

      this.dir = this.lang_data.type == '1' ? 'rtl' : 'ltr';
    }
    //Escucha evento para mostrar el menu lateral desde el componente de header
    window.addEventListener('show-sidebar', () => this.showSidebar());

    //Escucha evento para navegar a un modulo desde el componente de header
    window.addEventListener('navigate-module', (event: CustomEvent) => {
      console.log('Evento recibido: navigate-module', event.detail.module);
      this.navigateToModule(event.detail.module);
    })
  }

  @HostListener('window:resize', [])
  onResize() {
    this.isMobile = window.innerWidth <= 800;
  }

  toggleTheme(event) {
    if (event.detail.checked == true) {
      document.body.setAttribute('color-theme', 'dark');
      localStorage.setItem(Constant.darkMode, '1');

      this.statusBar.overlaysWebView(false);
      this.statusBar.backgroundColorByHexString('#000000');
      this.statusBar.styleDefault();
      this.statusBar.styleLightContent();


    } else {
      document.body.setAttribute('color-theme', 'light');
      localStorage.setItem(Constant.darkMode, '0');

      this.statusBar.overlaysWebView(false);
      this.statusBar.backgroundColorByHexString('#f4f5f8');
      this.statusBar.styleDefault();
    }
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.getUserStyles();
      if (this.setting && this.setting.id) {
        this.sub();
      }

      this.statusBar.styleDefault();
      this.splashScreen.hide();

      if (localStorage.getItem(Constant.darkMode) == '1') {
        this.statusBar.overlaysWebView(false);
        this.statusBar.backgroundColorByHexString('#000000');
        this.statusBar.styleDefault();
        this.statusBar.styleLightContent();


      } else {
        this.statusBar.overlaysWebView(false);
        this.statusBar.backgroundColorByHexString('#f4f5f8');
        this.statusBar.styleDefault();
      }
    });
  }

  sub() {
    this.oneSignal.startInit(this.setting.push_app_id, this.setting.push_google);

    this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.InAppAlert);

    this.oneSignal.handleNotificationReceived().subscribe(() => {
      // do something when notification is received
    });

    this.oneSignal.handleNotificationOpened().subscribe(() => {
      // do something when a notification is opened
    });


    if (localStorage.getItem(Constant.userId) && localStorage.getItem(Constant.userId) != undefined) {
      this.oneSignal.sendTags({ user_id: localStorage.getItem(Constant.userId) });
    }

    this.oneSignal.endInit();
  }

  logout() {
    localStorage.removeItem(Constant.userId);
    localStorage.removeItem(Constant.userData);
    //Se cierran los menus laterales
    this.isOpened = false;
    this.currentMenu = null;

    this.nav.navigateRoot('/login');
  }


  getUser() {
    console.log('Find user by id', this.userId);
    if (localStorage.getItem(Constant.userData1) && localStorage.getItem(Constant.userData1) != undefined) {
      this.user = JSON.parse(localStorage.getItem(Constant.userData));
    }
    if (this.isAuthorized === 'false') {
      return;
    }
    this.authService.getUserById(this.userId).subscribe((user) => {
      localStorage.setItem(Constant.userData1, JSON.stringify(user));
      this.user = user;
      this.filterMenuByRoles();   //aca debemos aplicar el menu dinamico para el usuario activo
    });
  }

  getUserStyles() {
    const defaultStyle = localStorage.getItem(Constant.defaultColor);
    if (defaultStyle) {
      this.setStyle(JSON.stringify(defaultStyle));
    }
    if (this.isAuthorized === 'false') {
      return;
    }
    this.authService.getStyles(this.userId).subscribe((styles) => {
      const style = styles[0];
      localStorage.setItem('default_color', JSON.stringify(style));
      this.setStyle(style);
    });
  }

  setStyle(style) {
    if (style.primary) {
      document.documentElement.style.setProperty('--ion-color-primary', style.primary);
      this.statusBar.backgroundColorByHexString(style.primary);
    }
    if (style.secondary) {
      document.documentElement.style.setProperty('--ion-color-secondary', style.secondary);
    }
  }

  async presentPopover(ev: any) {
    const popover = await this.popoverController.create({
      component: PopoverContentComponent,
      event: ev,
      translucent: true
    });
    return await popover.present();
  }

  //Metodos para el despliegue y cambio de menu en la barra lateral
  showSubMenu(menu: MenuItem, event: Event) {
    //Prevenimos acciones innecesarias y propagacion que pueda afectar el proceso apertura-cierre
    event.stopPropagation();
    if (event.type === 'mouseenter' && this.isMobile) {
      return;
    }

    // Si el mismo menú se selecciona, lo deseleccionamos    
    if (this.currentMenu === menu) {
      this.hideSubMenu();
      // Si es diferente menu se selecciona
    } else {
      //Si es un menu con items lo seleccionamos
      if (menu.items) {
        this.currentMenu = menu;
        //Si se dio click en menu Inicio se navega a la pagina principal
      } else if (menu.label === 'Inicio' && event.type === 'click') {
        console.log('el menu', this.currentMenu)
        this.navigateToModule(menu);
        //Si no se cumple la condiciones anteriores se oculta el menu ya que no tiene items para la segunda barra y no fue un click para dirigir a la pagina principal
      } else {
        this.hideSubMenu();
      }
    }
  }

  hideSubMenu() { this.currentMenu = null; }

  showSidebar() {
    if (this.isOpened) {
      this.isOpened = false;
      this.currentMenu = null;
    } else {
      this.isOpened = true;
    }
  }

  async navigateToModule(item) {
    await this.router.navigate([item.routerLink]);
    await this.updatebreadcrumb();
    if (this.isOpened) this.showSidebar();
  }

  //Actualiza el breadcrumb para que el componente header lo muestre con la ruta seleccionada
  // Se envia el breadcrumb por medio de breadcrumb service para que header.component lo capture y muestre
  async updatebreadcrumb() {
    const currentPath = this.router.url;
    let breadcrumb: MenuItem[] = [];
    console.log(this.currentMenu, currentPath)
    if (this.currentMenu) {
      breadcrumb = [
        //se agrega el menu principal
        { ...this.currentMenu },
        //Se agrega la ruta seleccionada en el menu principal
        ...this.currentMenu.items.filter((section) => section.routerLink === currentPath),
      ];

      console.log('Asi quedo el breadcrumb', breadcrumb);
      // Actualiza el servicio si hay elementos en el breadcrumb y la ruta seleccionada es distinta a la pagina principal
      if (breadcrumb.length > 0) this.breadcrumbService.updateBreadcrumb(breadcrumb);
    } else {
      this.breadcrumbService.updateBreadcrumb([]);
    }


  }

  //Consulta al backend por el JSON que contiene los modulos habilitados para el usuario
  async filterMenuByRoles() {
    try {
      this.authService.getUserSidebarModules().then((response: MenuItem[]) => {
        const menu: MenuItem[] = response;
        this.menuItems = [this.mainModule, ...menu];
        console.log('Los accesos habilitados para el usuario son:', this.menuItems);
      });
    } catch (error) {
      console.error('Error al obtener los accesos de barra lateral para el usuario', error);
      this.menuItems = [];
    }
  }
}

