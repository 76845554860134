import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {map} from "rxjs/operators";
import {environment} from "../../environments/environment";
import {EMPTY} from "rxjs";
import {Client} from '../shared/client.model';
import {SendMessage} from '../shared/sendMessage.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  urlLogin = environment.api.login;
  urlUser = environment.api.user;
  urlUserId = environment.api.userId;
  urlOtp = environment.api.otp;
  userStyle = environment.api.userStyle;
  recoveryPassword = environment.api.recoveryPassword;
  urlProviders = environment.api.providers;
  urlUpdateUser = environment.api.updateUser;
  urlTemporalPassword = environment.api.temporalPassword;
  urlAccountingInformation = environment.api.accountingInformation;
  urlSendOtp = environment.api.sendOtp;
  urlCommerces = environment.api.commerces;
  urlcommercesId = environment.api.commercesId;
  urlDistributors = environment.api.distributors;
  urlAccess = environment.api.access;
  profileAccess = environment.api.profileAccess;
  urlClient = environment.api.client;
  urlAddClient = environment.api.addClient;
  sendSms = environment.api.sendSms;
  sendWsp = environment.api.sendWsp;
  sendWhatsapp = environment.api.sendWhatsapp;
  urlRoles = environment.api.accessUser;
  urlCompanies = environment.api.companies;
  urlCompanyId = environment.api.companyId;
  urlInformationBusiness = environment.api.informationBusinessCommerce;
  urlDistributorId= environment.api.distribuitorId;
  urlGetBalance= environment.api.balance;
  urlPointOfSale = environment.api.pointOfSale;
  urlAcceptanceDeclarations = environment.api.acceptanceDeclarations;
  urlAuth = environment.api.auth;
  urlTypePlatform = environment.api.typePlatform;
  urlMenu = environment.api.menus;
  urlModule = environment.api.modules;
  urlProfile = environment.api.profiles;
  urlSidebarItems = environment.api.sidebarItemsCommerce;

  private rolesCache: any = null;
  private sidebarAccess: any = null;

  constructor(private http: HttpClient) {
  }


  login(data) {
    return this.http.post(this.urlLogin, data)
      .pipe(map(results => results));
  }

  validateOtp(data, userId: string) {
    return this.http.post(this.urlOtp.replace('{userId}', userId), data)
      .pipe(map(results => results));
  };

  createUser(data) {
    return this.http.post(this.urlUser, data)
      .pipe(map(results => results));
  }

  getAllUsers() {
    return this.http.get(this.urlUser)
      .pipe(map(results => results));
  }

  findByGoogle(uid: string) {
    return this.http.get(this.urlUser.concat('?googleUid=', uid))
      .pipe(map(results => results));
  }

  findByDocument(document: string) {
    return this.http.get(this.urlUser.concat('?document=', document))
      .pipe(map(results => results));
  }

  continueAction(document: string) {
    return this.http.get(this.urlUser.concat('?document=', document))
      .pipe(map(results => results));
  }

  getStyles(userId: string) {
    if (!userId) {
      return EMPTY;
    }
    const url = this.userStyle.replace('{userId}', userId);
    return this.http.get(url).pipe(map(results => results));
  }

  getUserById(userId: string) {
    if (!userId) {
      return EMPTY;
    }
    const url = this.urlUserId.replace('{userId}', userId);
    return this.http.get(url).pipe(map(results => results));
  }

  createStyle(userId, data) {
    const url = this.userStyle.replace('{userId}', userId);
    return this.http.post(url, data).pipe(map(results => results));
  }

  updateStyle(userId: string, data) {
    const url = this.userStyle.replace('{userId}', userId);
    return this.http.put(url, data).pipe(map(results => results));
  }

  recoverPassword(userId: string, data) {
    const url = this.recoveryPassword.replace('{userId}', userId);
    return this.http.put(url, data).pipe(map(results => results));
  }

  deleteUser(userId: any) {
    const url = this.urlUserId.replace('{userId}', userId);
    return this.http.delete(url).pipe(map(results => results));
  }

  createProviders(data) {
    return this.http.post(this.urlProviders, data)
      .pipe(map(results => results));
  }


  updateUser(userId: string, value) {
    const url = this.urlUpdateUser.replace('{userId}', userId);
    return this.http.put(url, value).pipe(map(results => results));
  }

  sendTemporalPassword(userId, data) {
    const url = this.urlTemporalPassword.replace('{userId}', userId);
    return this.http.put(url, data).pipe(map(results => results));
  }

  updateProvider(providerId: string, value) {
    const url = this.urlAccountingInformation.replace('{providerId}', providerId);
    return this.http.put(url, value).pipe(map(results => results));
  }

  sendOtp(userId, data) {
    const url = this.urlSendOtp.replace('{userId}', userId);
    return this.http.post(url, data)
      .pipe(map(results => results));

  }

  createCommerce(data) {
    return this.http.post(this.urlCommerces, data)
      .pipe(map(results => results));
  }

  createDistributor(data) {
    return this.http.post(this.urlDistributors, data)
      .pipe(map(results => results));
  }

  accountingInformationShops(commerceId: string, value) {
    const url = this.urlcommercesId.replace('{commerceId}', commerceId);
    return this.http.put(url, value).pipe(map(results => results));
  }
  accountingInformationDistributor(distributorId: string, value) {
    const url = this.urlDistributorId.replace('{distributorId}', distributorId);
    return this.http.put(url, value).pipe(map(results => results));
  }

  createAccess(value: any) {
    return this.http.post(this.urlAccess, value).pipe(map(results => results));
  }

  getAccessByProfile(profileId: any) {
    const url = this.profileAccess.replace('{profileId}', profileId);
    return this.http.get(url).pipe(map(results => results));
  }

  getClientByDocument(document: string) {
    const url = this.urlClient.replace('{document}', document);
    return this.http.get(url).pipe(map(results => results));
  }

  addClient(data: Client) {
    return this.http.post(this.urlAddClient, data)
      .pipe(map(results => results));
  }  

  sendSMS(data: SendMessage) {
    return this.http.post(this.sendSms, data)
      .pipe(map(results => results));
  }

  sendWathsapp(data: SendMessage) {
    return this.http.post(this.sendWsp, data)
      .pipe(map(results => results));
  }

  sendWhatsApp(data: SendMessage) {
    return this.http.post(this.sendWhatsapp, data)
      .pipe(map(results => results));
  }

  deleteAccess(accessId: any) {
    const url = this.urlAccess.concat('/' + accessId);
    return this.http.delete(url)
      .pipe(map(results => results));
  }

  createCompany(data) {
    return this.http.post(this.urlCompanies, data)
      .pipe(map(results => results));
  }

  updateCompany(companyId, data) {
    const url = this.urlCompanyId.replace('{companyId}', companyId);
    return this.http.put(url, data)
      .pipe(map(results => results));
  }

  getProviders() {
    return this.http.get(this.urlProviders)
      .pipe(map(results => results));
  }

  getCommerceId(commerceId: number){
    const url = this.urlcommercesId.replace("{commerceId}", commerceId.toString());
    return this.http.get(url)
    .pipe(map(results => results));
  }

  getCommerces(){
    return this.http.get(this.urlCommerces)
    .pipe(map(results => results));
  }

  getDistributorId(distributorId: number){
    return this.http.get(this.urlDistributorId)
    .pipe(map(results => results));
  }

  getDistributors(){
    return this.http.get(this.urlDistributors)
    .pipe(map(results => results));
  }

  accountingInformationBusinessShops(commerceId: string, value) {
    const url = this.urlInformationBusiness.replace('{commerceId}', commerceId);
    return this.http.put(url, value)
      .pipe(map(results => results));
  }

  getBalance(userId: string){
    const params = new HttpParams()
    .set('userId', userId);
    const url = this.urlGetBalance.replace('{userId}', userId);
    return this.http.get(url);
  }

  createPointOfSale(data){
    return this.http.get(this.urlPointOfSale)
    .pipe(map(results => results));
  }

  acceptanceDeclarations(data) {
    return this.http.post(this.urlAcceptanceDeclarations, data)
      .pipe(map(results => results));
  }

  paymentMethodForEntity(data) {
    const url = this.urlAuth.concat('entityPaymentMethod');
    return this.http.post(url,data)
      .pipe(map(response => response));
  }

  getAllTypePlatforms() {
    return this.http.get(this.urlTypePlatform)
      .pipe(map(results => results));
  }

  getTypePlatform(typePlatformId: string) {
    const url = this.urlTypePlatform.concat(`/${typePlatformId}`);
    return this.http.get(url)
      .pipe(map(results => results)); 
  } 

  async getModulesByUser(): Promise<any> {
    if (this.rolesCache !== null) {
      return Promise.resolve(this.rolesCache);
    } else {
      const roles = await this.http.get(this.urlRoles)
        .pipe(
          map(results => results))
        .toPromise();
      this.rolesCache = roles;
      return roles;
    }
  }

  async getUserSidebarModules(): Promise<any> {
    console.log('esto hay', this.sidebarAccess);
    if (this.sidebarAccess !== null) {
      return Promise.resolve(this.sidebarAccess);
    } else {
      const sidebar = await this.http.get(this.urlSidebarItems)
        .pipe(map(results => results))
        .toPromise();
      this.sidebarAccess = sidebar;
      console.log('esto quedo', this.sidebarAccess);
      return sidebar;
    }
  }

  getModule(moduleId: string) {
    const url = this.urlModule.concat(`/${moduleId}`);
    return this.http.get(url)
      .pipe(map(results => results));
  }

  getAllModule() {
    return this.http.get(this.urlModule)
      .pipe(map(results => results));
  }

  getMenu(menuId: string) {
    const url = this.urlMenu.concat(`/${menuId}`);
    return this.http.get(url)
      .pipe(map(results => results));
  }

  getAllMenu() {
    return this.http.get(this.urlMenu)
      .pipe(map(results => results));
  }

  createMenu(data) {
    return this.http.post(this.urlMenu, data)
      .pipe(map(results => results));
  }

  updateMenu(menuId: string, data) {
    const url = this.urlMenu.concat(`/${menuId}`);
    return this.http.patch(url, data)
      .pipe(map(results => results));
  }

  deleteMenu(menuId: string) {
    const url = this.urlMenu.concat(`/${menuId}`);
    return this.http.delete(url)
      .pipe(map(results => results));

  }

  getAllProfile() {
    return this.http.get(this.urlProfile)
      .pipe(map(results => results));
  }

  getProfile(profileId: string) {
    const url = this.urlProfile.concat(`/${profileId}`);
    return this.http.get(url)
      .pipe(map(results => results));
  }

  createProfile(data) {
    return this.http.post(this.urlProfile, data)
      .pipe(map(results => results));
  }

  updateProfile(profileId: string, data) {
    const url = this.urlProfile.concat(`/${profileId}`);
    return this.http.patch(url, data)
      .pipe(map(results => results));
  }

  deleteProfile(profileId: string) {
    const url = this.urlProfile.concat("/" + profileId);
    return this.http.delete(url)
      .pipe(map(results => results));
  }

}
