import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MenuItem } from '../shared/model/intefaces/Menu.model';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbHeaderService {

  private breadcrumbSource = new BehaviorSubject<MenuItem[]>([]);
  breadcrumb$ = this.breadcrumbSource.asObservable();

  updateBreadcrumb(breadcrumb: MenuItem[]) {
    this.breadcrumbSource.next(breadcrumb); // Actualiza el estado del breadcrumb
  }
}
