import { Component } from '@angular/core';
import { NavController,Platform,LoadingController,IonicSlides,ToastController,ModalController,NavParams } from '@ionic/angular';
import { ServerService } from '../service/server.service';
import { ActivatedRoute } from '@angular/router';
import {Constant} from "../shared/model/Constant";

@Component({
  selector: 'app-taskview',
  templateUrl: 'taskview.page.html',
  styleUrls: ['taskview.page.scss'],
})
export class TaskviewPage {

text:any;
data:any;
id:any;
reminder:any;
notes:any;
start_date:any;
end_date:any;
title:any;


constructor(public navParams: NavParams,public loadingController: LoadingController,public server : ServerService,private route: ActivatedRoute,public nav : NavController,public toastController: ToastController,public modalController: ModalController) {

this.text    = JSON.parse(localStorage.getItem(Constant.appText));
this.data    = navParams.get('data');
this.title   = this.data.status == 0 ? this.text.mark_complete : this.text.mark_uncom;


}

  ngOnInit()
  {

  }

  async close()
  {
    await this.modalController.dismiss({});
  }

  async complete(id)
  {
    await this.modalController.dismiss({id:id});
  }

  async presentToast(txt) {
    const toast = await this.toastController.create({
      message: txt,
      duration: 3000,
      position : 'top',
      mode:'ios',
      color:'dark'
    });
    toast.present();
  }
}
